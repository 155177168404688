import React, {Component, PropsWithChildren} from 'react';
import {
  AuthenticatedUser,
  AuthService,
  defaultTimeZone,
  Login,
  MessageService,
  ResetPassword,
  UsersService,
} from 'two-app-ui';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {PaCompany, User} from 'two-core';
import {Subscription} from 'rxjs';
import {IANAZone, Settings} from 'luxon';
import {config} from '../../config/config';
import './AppFrame.scss';
import Made2FitAppContext from '../../context/Made2FitAppContext';
import {ProgressSpinner} from 'primereact/progressspinner';
import Scheduler from '../Scheduler/Scheduler';
import JobDetail from '../Job/JobDetail';
import OriginalRequest from '../JobDocument/OriginalRequest/OriginalRequest';
import Dcm from '../JobDocument/Dcm/Dcm';
import {AppToolbar} from './AppToolbar/AppToolbar';
import CompaniesService from '../../services/CompaniesService';
import RightPart from './AppToolbar/RightPart';

interface State {
  isAuthenticated: boolean;
  loading: boolean;
  currentUser?: User;
  companies: PaCompany[];
}

export class AppFrame extends Component<PropsWithChildren<{}>, State> {
  static contextType = Made2FitAppContext;
  subscription: Subscription = new Subscription();
  authService?: AuthService;
  usersService?: UsersService;
  companiesService?: CompaniesService;

  constructor(props: {}) {
    super(props);
    this.state = {
      isAuthenticated: false,
      loading: true,
      companies: [],
    };
  }

  async componentDidMount() {
    this.authService = this.context.authService;
    this.usersService = this.context.usersService;
    this.companiesService = this.context.companiesService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === config().messages.loggedIn) {
        this.onLoggedIn();
      } else if (message === config().messages.loggedOut) {
        this.onLoggedOut();
      } else if (message === config().messages.topSelectionChanged) {
        this.onCompanyChanged();
      }
    });
    this.onLoggedIn();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  async onLoggedIn() {
    this.authService
      ?.isSessionValid()
      .then(async isAuthenticated => {
        await this.usersService?.updateMissingUserLocalStorage();
        const currentUser = await this.loadUser();
        await this.loadCompanies();
        this.setState({
          isAuthenticated: isAuthenticated,
          loading: false,
          currentUser,
        });
      })
      .catch(error => {
        this.setState({
          isAuthenticated: false,
          loading: false,
          currentUser: undefined,
        });
        console.error(error);
      });
  }

  onLoggedOut() {
    this.setState({companies: [], isAuthenticated: false, currentUser: undefined});
  }

  onCompanyChanged() {
    const newCompanyId = localStorage.getItem(config().ls_keys.current_company);
    const newCompany = this.state.companies.find(company => company.id === newCompanyId);

    if (this.usersService && newCompany) {
      localStorage.setItem(config().ls_keys.current_role, newCompany.my_role);
    }
  }

  /**
   * Load user aggregation for use in top menu components. E.g.: Show user's application.
   */
  async loadUser() {
    try {
      const {currentUser} = this.state;
      const userJsonString = localStorage.getItem('user') ?? '{}';
      const authenticatedUser: AuthenticatedUser = JSON.parse(userJsonString);
      if (!authenticatedUser.uuid) {
        return undefined;
      }
      // load user only once
      if (!currentUser) {
        const userResult = await this.usersService?.getUsers({
          aggregate: true,
          filters: [
            JSON.stringify({
              field: 'id',
              value: authenticatedUser.uuid,
            }),
          ],
        });
        return (userResult?.records as User[])[0];
      }
      return currentUser;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async loadCompanies() {
    const filters = [
      JSON.stringify({
        field: 'company_contact.in_role',
        value: 'fitter',
      }),
    ];
    const orderBys = [JSON.stringify({field: 'name', direction: 'ASC'})];
    this.companiesService
      ?.getCompanies({
        aggregate: ['company_contacts'],
        orderBys: orderBys,
        filters: filters,
      })
      .then(companiesApiListResponse => {
        const companies = (companiesApiListResponse.records as PaCompany[]) ?? [];

        if (!companies.length) {
          localStorage.removeItem(config().ls_keys.current_company);
          return;
        }

        const currentCompanyId = localStorage.getItem(config().ls_keys.current_company);
        let selectedCompany = companies.find(company => currentCompanyId === company.id);
        if (!currentCompanyId || !selectedCompany) {
          selectedCompany = companies[0];
          localStorage.setItem(config().ls_keys.current_company, selectedCompany.id);
          localStorage.setItem(config().ls_keys.current_role, selectedCompany.my_role);
        }

        this.setState({
          companies: companies,
        });

        MessageService.sendMessage(config().messages.topSelectionDataLoaded);
      })
      .catch(error => {
        localStorage.removeItem(config().ls_keys.current_company);
        throw new Error('Error ' + error);
      });
  }

  render() {
    const {companies} = this.state;
    return (
      <div id="layout-wrapper" className="p-d-flex p-flex-column">
        <BrowserRouter>
          {this.state.isAuthenticated ? (
            <React.Fragment>
              <AppToolbar right={<RightPart companies={companies} />} />
              {this.state.loading ? (
                <div className="overlay">
                  <ProgressSpinner className="overlay-spinner" />
                </div>
              ) : this.usersService?.permissions.length !== 0 ? (
                <div id="content" className="p-m-auto">
                  <Switch>
                    <Route path="/job/:jobId/original-request/:jobDocumentId">
                      <OriginalRequest />
                    </Route>
                    <Route path="/job/:jobId/dcm/:jobDocumentId/:tab">
                      <Dcm />
                    </Route>
                    <Route path="/job/:jobId/dcm/:jobDocumentId">
                      <Dcm />
                    </Route>
                    <Route path="/job/:id">
                      <JobDetail />
                    </Route>
                    <Route path="/scheduler">
                      <Scheduler />
                    </Route>
                    <Route path="/">
                      <Scheduler />
                    </Route>
                    <Route path="/:any">
                      <Scheduler />
                    </Route>
                  </Switch>
                </div>
              ) : (
                <div id="content" className="p-d-flex p-flex-row">
                  <div className="permission-denied-header">
                    <div className="permision-denied-title">You dont have permissions to use this app.</div>
                    <br />
                    <div className="permission-denied-subtitle">Reach out to your superior, if this is incorrect.</div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/reset_password">
                <ResetPassword />
              </Route>
              <Route path="/">
                <Login />
              </Route>
              <Route path="/:any">
                <Login />
              </Route>
            </Switch>
          )}
        </BrowserRouter>
      </div>
    );
  }
}

export default AppFrame;
